// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import { anagraficaPacchettiCategorie } from "@/models/carrello/categoria";
import { associaDistributoriPacchettiExtended, gruppoSchedaProdottoParametri, schedaProdottoParametri } from "@/models/carrello/prodotti";
import api from "@/services/api";
import { Component, Watch } from "vue-property-decorator";
import Vue from "vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import helpers from "@/helpers";
import { datiViaggio } from "@/models/carrello/datiViaggio";
import { parametriPreventivatoreItem } from "@/models/carrello/parametriPreventivatore";
import { TextValueItem } from "@/models/TextValueItem";
import eventHub from "@/eventHub";

const _ENDPONT_PARAMETRI = api.endpoint.CARRELLO.PARAMETRI;
const _ENDPONT_PREFERITI = api.endpoint.CARRELLO.PREFERITI;
const _ENDPONT_PREVENTIVATORE = api.endpoint.CARRELLO.PREVENTIVATORE;
@Component({
	computed: {
		...mapState({
			prodotti: (state: any) => state.prodotti,
			categoria: (state: any) => state.categoria,
			showPreferiti: (state: any) => state.showPreferiti,
			datiViaggio: (state: any) => state.datiViaggio,
			width: (state: any) => state.width
		})
	},
	methods: {
		...mapMutations(['addProdottoPreferito', 'removeProdottoPreferito', 'setShowPreferiti', 'setProdotti']),
		...mapGetters(["getSearchedProduct"])
	}
})
export default class carrelloListaProdottiPage extends baseContextComponent {
	getSearchedProduct: () => TextValueItem[] | null;
	isHoverArrowRight: boolean = false;
	isHoverArrowLeft: boolean = false;
	
	get showImportoViaggio(): boolean {
		return !(this.categoria != null && this.categoria.escludiImportoViaggio);
	}
	get isOneColumn(): boolean {
		return (this.width > 1200 || this.isAppMobile);
	}
	get parametriCarelloColums(): number {
		return this.width <= 1200 ? 1 : this.lg ? 4 : this.md ? 2 : 1;
	}
	prodottiScroll: associaDistributoriPacchettiExtended[] = [];
	indexBack: number = 0;
	index: number = 0;
	// schedeProdottiParametri: gruppoSchedaProdottoParametri[] = [];
	//sono gia ordinati lato server
	parametri: gruppoSchedaProdottoParametri[] = [];
	// get parametri(): gruppoSchedaProdottoParametri[] {
	// 	return this.schedeProdottiParametri.sort((a, b) => (a.ordine > b.ordine) ? 1 : (a.ordine == b.ordine) ? (a.categoria > b.categoria) ? 1 : -1 : -1);
	// }
	get parametriConValori(): gruppoSchedaProdottoParametri[] {
		var self = this;
		if (self.parametri == null)
			return [];
		return self.parametri.filter(x=> self.parametroHasValori(x));
	}
	parametroHasValori(parametro: gruppoSchedaProdottoParametri) { 
		var self = this;
		if (self.prodotti == null || self.prodotti.length == 0)
			return false;
		// basta che ci sia almeno un prodotto con almeno un parametro valorizzato per categoria del parametro in questione [quello passato al metodo]
		return self.prodotti.find(p => p.parametri.find(pp => pp.categoria == parametro.categoria && pp.valore && pp.valore != "0")) != null;
	}
	categorieEspanse: Array<string> = [];
	showPreferitiToggle: boolean = false;

	prodotti!: associaDistributoriPacchettiExtended[];
	categoria!: anagraficaPacchettiCategorie;
	showPreferiti!: boolean;
	datiViaggio!: datiViaggio;
	width!: number;
	addProdottoPreferito!: (prodotto: associaDistributoriPacchettiExtended) => void;
	removeProdottoPreferito!: (prodotto: associaDistributoriPacchettiExtended) => void;
	setShowPreferiti!: (showPreferiti: boolean) => void;
	setProdotti!: (prodotti: associaDistributoriPacchettiExtended[]) => void;

	get pageSize(): number {
		if (this.width > 2465) return 9;
		else if (this.width > 2260) return 8;
		else if (this.width > 2063) return 7;
		else if (this.width > 1860) return 6;
		else if (this.width > 1664) return 5;
		else if (this.width > 1470) return 4;
		else if (this.width > 1268) return 3;
		else if (this.width > 1140) return 2;
		else if (this.width > 990) return 2;
		else if (this.width > 900) return 2;
		else return 2;
	}

	async created() {
		await this.getParametri();

		if (false)// tengo le categorie collassate all'inizializzazione
			for (var i = 0; i < this.parametri.length; i++) {
				var parRoot = this.parametri[i];
				var categoria = parRoot.categoria;

				this.categorieEspanse.push(categoria);
			}
		this.showPreferitiToggle = this.showPreferiti;
		await this.togglePreferiti();

		var self = this;
		eventHub.$on(
			eventHub.events.PRODUCT_SEARCHED,
			() => self.changeProdottiScroll()
		);

		eventHub.$on(
			eventHub.events.TOGGLE_PREFERITI,
			async () => {
				self.togglePreferiti();
			}
		);
	}

	mounted() { }

	async getParametri() {
		var res = await api.get(`${_ENDPONT_PARAMETRI}`);
		this.parametri = res || [];
	}

	@Watch('pageSize', { immediate: true })
	async changePageSize() {
		await this.changeProdottiScroll(false);
	}

	//@Watch("prodotti", { immediate: true })
	async changeProdottiScroll(loadParametri: boolean = true) {
		this.prodottiScroll = [];
		if (this.prodotti.length >= this.pageSize) this.index = this.pageSize;
		else this.index = this.prodotti.length;
		for (var i = 0; i < this.index; i++) {
			this.prodottiScroll.push(this.prodotti[i]);
		}
		// if (loadParametri)
		// 	await this.getParametri();
		//await this.togglePreferiti();
	}

	goOn() {
		this.prodottiScroll = [];
		this.indexBack = this.index;
		if (this.index + this.pageSize > this.prodotti.length) this.index = this.prodotti.length;
		else this.index += this.pageSize;
		for (var i = this.indexBack; i < this.index; i++) {
			this.prodottiScroll.push(this.prodotti[i]);
		}
	}

	goBack() {
		this.prodottiScroll = [];
		var scarto = 0;
		if (this.index == this.prodotti.length) {
			scarto = this.index % this.pageSize;
			if (scarto > 0) this.index -= scarto;
			else this.index -= this.pageSize;
		} else this.index -= this.pageSize;
		this.indexBack -= this.pageSize;
		for (var i = this.indexBack; i < this.index; i++) {
			this.prodottiScroll.push(this.prodotti[i]);
		}
	}

	expandCategoria(categoria) {
		if (this.categorieEspanse.indexOf(categoria) == -1)
			this.categorieEspanse.push(categoria);
		else
			this.categorieEspanse = this.categorieEspanse.filter(x => x != categoria);
		// Vue.set(this.categorie, categoria, !this.categorie[categoria]);
	}
	nascondiParametro(parametro): boolean {
		return false;
	}
	isCategoriaEspanded(categoria) {
		return this.categorieEspanse.find(x => x == categoria) != null;
	}

	onChangePreferredVisibility() {
		this.showPreferitiToggle = !this.showPreferitiToggle;
		this.togglePreferiti();
	}

	async togglePreferiti() {
		
		var preferiti = await api.get(_ENDPONT_PREFERITI);
		this.setShowPreferiti(this.showPreferitiToggle);
		for (var i = 0; i < preferiti.length; i++) {
			if (this.showPreferiti && (!this.categoria || this.categoria.itemID == preferiti[i].categoriaID) || (this.categoria && (this.categoria.descrizione == 'preferiti'))) {
				preferiti[i].preferito = true;
				this.addProdottoPreferito(preferiti[i]);
			} else {
				this.removeProdottoPreferito(preferiti[i]);
			}
		}

		await this.changeProdottiScroll(false);
	}

	async ricalcolaProdotti() {
		var par = helpers.cloneObject(this.datiViaggio.parametri) as parametriPreventivatoreItem;
		par.categoriaID = this.categoria ? this.categoria.itemID : null;
		par.destinazioneGruppoStato = par.destinazioneGruppoStato ? ((par.destinazioneGruppoStato as any).value as number) || null : null;
		par.destinazioneStato = par.destinazioneStato ? ((par.destinazioneStato as any).value as number) || null : null;
		par.nazionalita = par.nazionalita ? ((par.nazionalita as any).value as number) || 1 : 1;
		par.eta = par.eta ? ((par.eta as any).value as number) || null : null;
		var isImportoObbligatorioPerTipologia = par.tipologiaPolizza ? ((par.tipologiaPolizza as any).isImportoObbligatorio	as boolean) || false : false;
		par.tipologiaPolizza = par.tipologiaPolizza ? ((par.tipologiaPolizza as any).value as number) || null : null;

		if (par.inizioViaggio && par.inizioViaggio && par.inizioViaggio > par.fineViaggio) {
			this.$bvToast.toast("La data di inizio viaggio non può essere successiva a quella di inizio viaggio", { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			return;
		}

		if (par.tipologiaPolizza && isImportoObbligatorioPerTipologia && (isNaN(par.importoViaggio) || par.importoViaggio <= 0)) {
			this.$bvToast.toast(`Per la tipologia di polizza da filtrare l'importo è obbligatorio`, { variant: "danger", title: this.$i18n.t("msg.erroreGenerico").toString(), solid: true });
			return;
		}

		var productSelected = this.getSearchedProduct();
		if (productSelected && productSelected.length) {
			par.prodottiSelezionati = productSelected.map((x: TextValueItem) => {
				return x.value as number;
			});
		}

		var filtraPreferiti = "";

		if(  this.categoria && this.categoria.descrizione === "preferiti"  )
			filtraPreferiti = "?filtraPreferiti=true";

		var res = await api.chimataPost<associaDistributoriPacchettiExtended[]>(`${_ENDPONT_PREVENTIVATORE}${filtraPreferiti}`, par);
		this.setProdotti(res.data);

		eventHub.$emit(eventHub.events.PRODUCT_SEARCHED);
	}

	routerGoBack() {
		eventHub.$emit(eventHub.events.RESET_PRODUCT_SEARCHED);
		this.$router.go(-1);
	}

	onMouseHover(prodotto: any) {
		Vue.set(prodotto, "isHover", true);
	}

	onMouseLeave(prodotto: any) {
		Vue.set(prodotto, "isHover", false);
	}
}
